export default class {

  // ------------------------------------------
  // コンストラクタ
  // ------------------------------------------
  constructor(selectedContainer, showList, selectList) {
    this.selectedContainer = selectedContainer; // 選択されたアイテムを表示する領域
    this.showList = showList; // 選択可能なアイテムをユーザーに選択させるための領域
    this.selectList = selectList; // ユーザーが選択したアイテムを内部的に保持するための領域（画面上非表示）
  }


  // ------------------------------------------
  // 選択可能なリストを表示する
  // ------------------------------------------
  show() {
    this.showList.show();
  }

  // ------------------------------------------
  // 選択可能なリストを閉じる
  // ------------------------------------------
  closeList() {
    this.showList.hide();
  }

  // ------------------------------------------
  // 選択したアイテムの☓ボタン押下
  // ------------------------------------------
  clickRemoveBtn(clickedItem) {
    const dataId = clickedItem.parent().attr('data-id');
    // 背景の色を消す
    this.showList.children('li[data-id="' + dataId + '"]').removeClass('js-selected-list');
    // オプションボタンを非選択状態にする
    this.setOption(dataId, false);
    // データ入力欄から選択したデータを削除する
    this.setSelectedItem(dataId, clickedItem.html(), false);
  }

  // ------------------------------------------
  // リスト上のアイテムをクリックしたときの処理
  // ------------------------------------------
  clickListItem(clickedItem) {
    const dataId = clickedItem.attr('data-id');
    if (clickedItem.hasClass('js-selected-list')) { // そのアイテムが選択状態の場合
      // 背景の色を消す
      clickedItem.removeClass('js-selected-list');
      // オプションボタンを非選択状態にする
      this.setOption(dataId, false);
      // データ入力欄から選択したデータを削除する
      this.setSelectedItem(dataId, clickedItem.html(), false);
    }
    else {  // そのアイテムが非選択状態の場合
      // 背景に色を付ける
      clickedItem.addClass('js-selected-list');
      // オプションボタンを選択状態にする
      this.setOption(dataId, true);
      // データ入力欄に選択したデータを追加する
      this.setSelectedItem(dataId, clickedItem.html(), true);
    }
  }

  // ------------------------------------------
  // 選択状態をクリアする
  // ------------------------------------------
  clear() {
    // 背景の色を消す
    this.showList.children('li').removeClass('js-selected-list');
    // オプションボタンを非選択状態にする
    this.selectList.children('option').prop('selected',false);
    // データ入力欄から選択したデータを削除する
    this.selectedContainer.children('div').remove();
    // placeholderを表示する
    this.selectedContainer.children('.js-placeholder').show();
  }

  // ------------------------------------------
  // 初期値を設定する（画面表示時）
  // ------------------------------------------
  init() {
    let values = [];
    let labels = [];

    // オプションボタンが選択状態のものの値を取得する
    this.selectList.children('option:selected').each(function() {
      values.push($(this).attr('value'));
      labels.push($(this).html());
    });
    for (let i = 0; i < values.length; i++) {
      // 背景の色をつける
      this.showList.children('li[data-id="' + values[i] + '"]').addClass('js-selected-list');

      // 入力欄に表示する
      this.setSelectedItem(values[i], labels[i], true);
    }
  }

  // *********** 以下、ローカル関数 ********************

  // ------------------------------------------
  // 機能：隠れているオプションボタンの選択・非選択を切り替える
  // 引数：value データの値
  //     selected trueが選択状態にする、falseが選択を外す
  // 戻値:なし
  // ------------------------------------------
  setOption(value, selected) {
    // 該当するデータ値のものがあれば、選択or選択解除
    this.selectList.children('option[value="' + value + '"]').prop('selected',selected);
  }

  // ------------------------------------------
  // 機能：選択したアイテムを入力欄に追加or削除する
  // 引数：name select領域の名前
  //     value データの値
  //     text アイテム名称
  //     add trueが追加、falseが削除
  // 戻値:なし
  // ------------------------------------------
  setSelectedItem(value, text, add) {
    if (add) {
      // データを追加する
      this.selectedContainer.append('<div class="js-selected-item" data-id="' + value +'"><div class="js-remove-item"></div>' + text + '</div>');
      // placeholderを表示しない
      this.selectedContainer.children('.js-placeholder').hide();
    }
    else {
      // データを削除する
      this.selectedContainer.children('div[data-id="' + value + '"]').remove();
      if (this.selectedContainer.children('div').length < 1) {
        // placeholderを表示する
        this.selectedContainer.children('.js-placeholder').show();
      }
    }
  }
}
