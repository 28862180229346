let scrollPosition = 0; // スクロール位置

// ------------------------------------------
// タブクリック時の処理
// ------------------------------------------
$('.search-tab').on('click', function() {
  $('.js-selected-tab').removeClass('js-selected-tab');
  $('.search-input-container').removeClass('js-selected-tab-sale');
  $('.search-input-container').removeClass('js-selected-tab-investment');
  $(this).addClass('js-selected-tab');
  $('.search-input-container').addClass($(this).attr('data-tab'));
  $("#save-tab").attr('value', $(this).attr('data-tab').replace('js-selected-tab-', ''));
});

// ********* 対象国 *********
import checkListModal from './class/checkListModal';
const country = new checkListModal($('#country-selected-items'), $('#country-modal'), $('#country-modal-bg'), $('#country-select'));
// ------------------------------------------
// モーダルのチェックボックス変化時の処理
// ------------------------------------------
$('#country-modal input[type=checkbox]').change(function(){
  country.updateParentCheckBox($(this));
});
// ------------------------------------------
// モーダルの選択肢全て解除ボタン押下
// ------------------------------------------
$('#check-clear-btn').on('click', function() {
  country.clearModalCheck()
});
// ------------------------------------------
// モーダルの設定するボタン押下
// ------------------------------------------
$('#set-country-btn').on('click', function() {
  country.reflectAndCloseModal();
});
// ------------------------------------------
// モーダルの背景押下
// ------------------------------------------
$('#country-modal-bg').on('click', function() {
  country.closeModal();
});
// モーダルの領域クリック時には、モーダルを閉じない
$('#country-modal').on('click', function(e) {
  e.stopPropagation();
});
// ------------------------------------------
// モーダルの閉じるボタン押下
// ------------------------------------------
$('#country-modal-close-btn').on('click', function() {
  country.closeModal();
});
// ------------------------------------------
// 国名の☓ボタン押下
// ------------------------------------------
$('body').on('click', '#country-selected-items .js-remove-item' , function() {
  country.clickRemoveBtn($(this));
});

// ********* 業種 *********
import TextList from './class/TextList';
const industry = new TextList($('#industry-selected-items'), $('#industry-list'), $('#industry-select'));
// ------------------------------------------
// 業界のリスト押下
// ------------------------------------------
$('#industry-list>li').on('click', function() {
  industry.clickListItem($(this));
});
// ------------------------------------------
// 業界の☓ボタン押下
// ------------------------------------------
$('body').on('click', '#industry-selected-items .js-remove-item' , function() {
  industry.clickRemoveBtn($(this));
});

// ********* 金額 *********
const saleAmount = new TextList($('#sale-amount-selected-items'), $('#sale-amount-list'), $('#sale-amount-select'));
// ------------------------------------------
// 金額のリスト押下
// ------------------------------------------
$('#sale-amount-list>li').on('click', function() {
  saleAmount.clickListItem($(this));
});
// ------------------------------------------
// 金額の☓ボタン押下
// ------------------------------------------
$('body').on('click', '#sale-amount-selected-items .js-remove-item' , function() {
  saleAmount.clickRemoveBtn($(this));
});

// ********* その他 *********
// ------------------------------------------
// 選択肢を全て解除ボタン押下処理
// ------------------------------------------
$('#all-clear-btn').on('click', function() {
  $('.js-keyword').val('');
  country.clear();
  industry.clear();
  saleAmount.clear();
});

// ------------------------------------------
// 画面クリック時の処理
// ------------------------------------------
$(document).on('click',function(e) {
  // モーダルが非表示のとき
  if ($('#country-modal-bg').css('display') === 'none') {
    if($(e.target).closest('#search-industry').length) {
      industry.show();
      saleAmount.closeList();
    }
    else if($(e.target).closest('#search-sale-amount').length) {
      saleAmount.show();
      industry.closeList();
    }
    else if ($(e.target).closest('#search-country').length) {
      country.show();  // モーダルを表示する
    }
    else {
      industry.closeList();
      saleAmount.closeList();
    }
  }
});

// スクロール時にイベントを無視するイベントハンドラ
var scrollControl = function(event) {
  if ($(event.target).closest('.country-modal__container ul').length > 0) {
    event.stopPropagation();
  } else {
    event.preventDefault();
  }
};

// ------------------------------------------
// 画面表示時
// ------------------------------------------
$(document).ready(function(){
  // タブの設定
  const tabName = $("#save-tab").attr('value');
  if (tabName !== 'sale') {
    // 売却物件を選択していないときは処理をタブを変更する
    $('.js-selected-tab').removeClass('js-selected-tab');
    $('.search-input-container').removeClass('js-selected-tab-sale');
    $('.search-input-container').removeClass('js-selected-tab-investment');
    $('.tab-' + tabName).addClass('js-selected-tab');
    $('.search-input-container').addClass('js-selected-tab-' + tabName);
  }
  // 初期値として選択されている項目を選択状態とする
  country.init();
  industry.init();
  saleAmount.init();
});
