export default class {

  // ------------------------------------------
  // コンストラクタ
  // ------------------------------------------
  constructor(selectedContainer, modal, modalAll, selectList) {
    this.selectedContainer = selectedContainer; // 選択されたアイテムを表示する領域
    this.modal = modal; // 選択可能なアイテムをユーザーに選択させるためのモーダル
    this.modalAll = modalAll; // 画面全体の背景を含んだモーダル全体
    this.selectList = selectList; // ユーザーが選択したアイテムを内部的に保持するための領域（画面上非表示）
    this.scrollPosition = 0;    // スクロール位置（モーダル表示中にスクロールを禁止するために使用）
  }

  // ------------------------------------------
  // モーダルを表示する
  // ------------------------------------------
  show() {
    // スクロールを禁止する
    this.scrollPosition = $(window).scrollTop();
    $('body').addClass('js-fixed').css({'top': -this.scrollPosition});
    this.modalAll.fadeIn(100);
  }

  // ------------------------------------------
  // モーダルを閉じる
  // ------------------------------------------
  closeModal() {
    // スクロールを許可する
    $('body').removeClass('js-fixed').css({'top': 0});
    window.scrollTo(0, this.scrollPosition);
    this.modalAll.fadeOut(100);
  }

  // ------------------------------------------
  // モーダルのチェック状態変化時の処理（親チェックボックスにチェックを入れるor入れない）
  // ------------------------------------------
  updateParentCheckBox(changeCheck) {
    const kind = changeCheck.closest('ul');  // 親チェックボックスor子チェックボックスか(ul要素)
    let parentId = "";  // 親チェックボックスのID
    if (kind.hasClass("js-check-parent")) {
      // 親チェックボックスの場合
      parentId = changeCheck.attr('value');
      const checked = changeCheck.prop('checked');
      this.modal.find('input[type=checkbox][data-parent='+ parentId + ']').each(function() {
        $(this).prop('checked', checked);
      });
    }
    else {
      // 子チェックボックスの場合
      parentId = changeCheck.attr('data-parent')
      let parentCheck = true;  // 親チェックボックスにチェックを入れる
      if (kind.find('input[type=checkbox]:checked').length < 1) {
        // 横並びのチェックボックスが全てチェックついていない場合
        parentCheck = false;
      }
      this.modal.find('.js-check-parent input[type=checkbox][value='+ parentId + ']').prop('checked', parentCheck);
    }
  }

  // ------------------------------------------
  // モーダルのチェックをすべて外す
  // ------------------------------------------
  clearModalCheck() {
    this.modal.find('input[type=checkbox]').each(function() {
      $(this).prop('checked', false);
    });
  }

  // ------------------------------------------
  // モーダルの状態を全てに反映する
  // ------------------------------------------
  reflectAndCloseModal() {
    // オプションボタンを全て非選択状態にする
    this.selectList.children('option:selected').each(function() {
      $(this).prop('selected',false);
    });
    // 入力欄に入っているものを全て削除
    this.selectedContainer.find('div').each(function() {
      $(this).remove();
    });

    let values = [];
    let labels = [];

    this.modal.find('.js-check-children input[type=checkbox]:checked').each(function() {
      values.push($(this).attr('value'));
      labels.push($(this).siblings('label').html());
    });
    for (let i = 0; i < values.length; i++) {
      // オプションボタンを選択状態にする
      this.setOption(values[i], true);
      // データ入力欄に選択したデータを追加する
      this.setSelectedItem(values[i], labels[i], true);
    }

    // データがない場合は、placeholderを表示する
    if (values.length > 0) {
      this.selectedContainer.children('.js-placeholder').hide();
    }
    else {
      this.selectedContainer.children('.js-placeholder').show();
    }

    // モーダルを閉じる
    this.closeModal();
  }

  // ------------------------------------------
  // 選択状態をクリアする
  // ------------------------------------------
  clear() {
    // チェックボックスのチェックを外す
    this.modal.find('input[type=checkbox]').each(function() {
      $(this).prop('checked', false);
    });
    // オプションボタンを非選択状態にする
    this.selectList.children('option').prop('selected',false);
    // データ入力欄から選択したデータを削除する
    this.selectedContainer.children('div').remove();
    // placeholderを表示する
    this.selectedContainer.children('.js-placeholder').show();
  }

  // ------------------------------------------
  // 選択したアイテムの☓ボタン押下
  // ------------------------------------------
  clickRemoveBtn(clickedItem) {
    const dataId = clickedItem.parent().attr('data-id');
    // オプションボタンを非選択状態にする
    this.setOption(dataId, false);
    // データ入力欄から選択したデータを削除する
    this.setSelectedItem(dataId, clickedItem.html(), false);
    // モーダルのチェックボックスからチェックを外す
    const checkbox = this.modal.find('input[type=checkbox][value=' + dataId + ']');
    checkbox.prop('checked', false);
    const parentId = checkbox.attr('data-parent');
    if (this.modal.find('.js-check-children input[type=checkbox][data-parent=' + parentId +']:checked').length < 1) {
      this.modal.find('.js-check-parent input[type=checkbox][value='+ parentId + ']').prop('checked', false);
    }
  }

  // ------------------------------------------
  // 初期値を設定する（画面表示時）
  // ------------------------------------------
  init() {
    let values = [];
    let labels = [];

    // オプションボタンが選択状態のものの値を取得する
    this.selectList.children('option:selected').each(function() {
      values.push($(this).attr('value'));
      labels.push($(this).html());
    });
    for (let i = 0; i < values.length; i++) {
      // モーダルのチェックボックスにチェックを入れる
      const checkbox = this.modal.find('input[type=checkbox][value=' + values[i] + ']');
      checkbox.prop('checked', true);
      const parentId = checkbox.attr('data-parent');
      this.modal.find('.js-check-parent input[type=checkbox][value='+ parentId + ']').prop('checked', true);

      // 入力欄に表示する
      this.setSelectedItem(values[i], labels[i], true);
    }
  }


  // *********** 以下、ローカル関数 ********************

  // ------------------------------------------
  // 機能：隠れているオプションボタンの選択・非選択を切り替える
  // 引数：value データの値
  //     selected trueが選択状態にする、falseが選択を外す
  // 戻値:なし
  // ------------------------------------------
  setOption(value, selected) {
    // 該当するデータ値のものがあれば、選択or選択解除
    this.selectList.children('option[value="' + value + '"]').prop('selected',selected);
  }

  // ------------------------------------------
  // 機能：選択したアイテムを入力欄に追加or削除する
  // 引数：name select領域の名前
  //     value データの値
  //     text アイテム名称
  //     add trueが追加、falseが削除
  // 戻値:なし
  // ------------------------------------------
  setSelectedItem(value, text, add) {
    if (add) {
      // データを追加する
      this.selectedContainer.append('<div class="js-selected-item" data-id="' + value +'"><div class="js-remove-item"></div>' + text + '</div>');
      // placeholderを表示しない
      this.selectedContainer.children('.js-placeholder').hide();
    }
    else {
      // データを削除する
      this.selectedContainer.children('div[data-id="' + value + '"]').remove();
      if (this.selectedContainer.children('div').length < 1) {
        // placeholderを表示する
        this.selectedContainer.children('.js-placeholder').show();
      }
    }
  }
}


